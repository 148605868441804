var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row--35",attrs:{"align":"start"}},[_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12","cols":"12","order":"2","order-md":"1"}},[_c('div',{staticClass:"text-left section-title mb--50"},[_c('span',{staticClass:"subtitle"},[_vm._v("Let's Say Hi")]),_c('h2',{staticClass:"heading-title"},[_vm._v("Contact With Us.")]),_c('div',{staticClass:"im_address"},[_c('span',[_vm._v("Call us directly:")]),_c('a',{staticClass:"link im-hover",attrs:{"href":"tel:+111 (0)55 5869 8976"}},[_vm._v("+111 (0)55 5869 8976")])]),_c('div',{staticClass:"im_address mt--5"},[_c('span',[_vm._v("Contact Email:")]),_c('a',{staticClass:"link im-hover",attrs:{"href":"mailto:contact@codepeckers.com"}},[_vm._v("contact@codepeckers.com")])])]),_c('div',{staticClass:"form-wrapper"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Your Name *"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"Your email *"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subject),expression:"formData.subject"}],attrs:{"type":"text","placeholder":"Write a Subject"},domProps:{"value":(_vm.formData.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subject", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":"Your Message"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"btn-default",attrs:{"type":"submit","value":"submit"}},[_vm._v(" Submit Now ")])],1)]}}])})],1)]),_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12","cols":"12","order":"1","order-md":"2"}},[_c('div',{staticClass:"thumbnail mb_md--40 mb_sm--40"},[_vm._t("contact-img")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }